import React from 'react'
import styled from 'styled-components'
import { Row, Col, Divider } from 'antd'
import { StickyContainer, Sticky } from 'react-sticky'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import roadToRecoveryCover from '../../static/images/road-to-recovery.png'

const StyledWrapper = styled.div`
  padding: 50px 0px;
  background-color: #ecececf0;

  .content-title {
    font-weight: bold;
    color: ${props => props.theme.bluePalette.original};
  }
  .release-date {
    line-height: 1;
    font-size: 0.9em;
    span {
      background-color: ${props => props.theme.primeBluePalette.original};
      padding: 8px 14px;
      border-radius: 50px;
      color: white;
    }
  }

  .package-title {
    font-weight: 900;
    color: ${props => `${props.theme.blackPalette.original}E6`};
  }

  .ant-divider.ant-divider-horizontal {
    background: ${props => `${props.theme.blackPalette.original}E6`};
  }

  .publication-cover {
    max-width: 100%;
    margin-bottom: 0px;
  }

  .btn-preview {
    background-color: #003366;
    font-weight: bold;
    font-size: 1.12em;
    color: white;
    padding: 6px 12px;
    :hover {
      opacity: 0.8;
    }
  }
`

function NavigatingThroughTidesComponent() {
  return (
    <StyledWrapper>
      <StickyContainer>
        <Row type="flex" justify="center">
          <Col
            xs={0}
            sm={0}
            md={7}
            lg={7}
            xl={{ span: 6, offset: 2 }}
            xxl={{ span: 5, offset: 2 }}
          >
            <Sticky topOffset={-105}>
              {({ style, distanceFromBottom }) => {
                const topPosition =
                  distanceFromBottom > 114 ? { top: '114px' } : null
                return (
                  <header style={{ ...style, ...topPosition }}>
                    <img
                      className="publication-cover"
                      src={roadToRecoveryCover}
                      alt="The Road to Recovery: Real Estate Sentiments and Strategy Conspectus"
                    />
                  </header>
                )
              }}
            </Sticky>
          </Col>

          <Col xs={18} sm={18} md={0} lg={0} xl={{ span: 0 }} xxl={{ span: 0 }}>
            <img
              className="publication-cover"
              src={roadToRecoveryCover}
              alt="The Road to Recovery: Real Estate Sentiments and Strategy Conspectus"
            />
          </Col>

          <Col
            xs={22}
            sm={22}
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 9, offset: 1 }}
            xxl={{ span: 9, offset: 1 }}
          >
            <br />
            <div className="release-date">
              <span>VOLUME 2 (September 2020)</span>
            </div>
            <br />
            <div>
              <h3 className="package-title">
                THE ROAD TO RECOVERY: REAL ESTATE SENTIMENTS AND STRATEGY
                CONSPECTUS
              </h3>
              <p>
                Analyzing collective insights from real estate practitioners
                from various sectors – office, retail, residential, hotel, and
                industrial, this report focuses on the strategies stakeholders
                may apply to recuperate from the adverse effects of the COVID-19
                outbreak and how to move forward to sustain long-term business
                continuity.
              </p>
              <OutboundLink
                type="primary"
                href="https://assets.primephilippines.com/pdfs/publications/PRIME_Philippines_Compendium_Vol_2_Free_Version.pdf"
                target="_blank"
                id="preview-btn-id"
              >
                <span className="btn-preview">Download Free Preview</span>
              </OutboundLink>
            </div>
            <Divider />
            <p className="content-title">Philippine Economic Overview</p>
            <p className="content-details">
              Be informed on the Philippines' current economic standings, an
              update of the three main legs of the economy, and outlook on
              general business
            </p>

            <p className="content-title">
              Metro Manila Property Market Overview
            </p>
            <p className="content-details">
              Update yourself on the current standing of the office, retail,
              residential, hotel, industrial, and land sector of Metro Manila.
              Be in-the-know of current market trends and adjustments made per
              property sector.
            </p>

            <p className="content-title">
              COVID-19 Impact on the Commercial Sector of Real Estate
            </p>
            <p className="content-details">
              Know the historical impact of crisis on office rental and
              occupancy rates, the reactions done by retail landlords and
              tenants, and the outlook on the commercial sector.
            </p>

            <p className="content-title">Real Property Buy-Sell Spectrum</p>
            <p className="content-details">
              Learn of the property market's movement from strongly sellers'
              market and whether it has fully transitioned into a buyers market,
              along with the impact of such market shifts
            </p>

            <p className="content-title">
              Cebu and Davao Property Market Overview
            </p>
            <p className="content-details">
              Be provided a snapshot of the property market's state and the
              movements and adjustments experienced in the Queen and King Cities
              of the South
            </p>
          </Col>
        </Row>
      </StickyContainer>
    </StyledWrapper>
  )
}

export default NavigatingThroughTidesComponent
