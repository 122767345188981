import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactFormComponent'
import HeroSectionComponent from '../components/HeroSectionComponent'
import TheRoadToRecoveryComponent from '../components/TheRoadToRecoveryComponent'
import OtherVolumesComponent from '../components/OtherVolumesComponent'

import ogCover from '../../static/images/og-cover-volume2.jpg'
import { volumes } from './navigating-through-tides-volume-1'

const StyledDivWrapper = styled.div`
  background: #003366;
  position: relative;
  z-index: 0;

  /* :after {
    position: absolute;
    z-index: -1;
    content: ' ';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('../../images/high-rise-buildings-bg.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  } */
`

const IndexPage = props => (
  <Layout>
    <SEO
      title="THE ROAD TO RECOVERY: Real Estate Sentiments and Strategy Conspectus"
      description="Analyzing collective insights from real estate practitioners from various sectors – office, retail, residential, hotel, and industrial, this report focuses on the strategies stakeholders may apply to recuperate from the adverse effects of the COVID-19 outbreak and how to move forward to sustain long-term business continuity."
      meta={[
        {
          property: `og:image`,
          content: ogCover,
        },
        {
          name: `keywords`,
          content: `
          Philippine Real Estate Business Intelligence Compendium,
          Cebu and Davao Property Market Overview,
          THE ROAD TO RECOVERY: REAL ESTATE SENTIMENTS AND STRATEGY CONSPECTUS,
          Philippine Economic Overview,
          Metro Manila Property Market Overview,
          COVID-19 Impact on the Commercial Sector of Real Estate,
          Real Property Buy-Sell Spectrum,
          Cebu and Davao Property Market Overview,
          PRIME Philippines
          `,
        },
      ]}
    />
    <StyledDivWrapper>
      <HeroSectionComponent />
      <TheRoadToRecoveryComponent />
      <OtherVolumesComponent volumes={[volumes.no1, volumes.no3]} {...props} />
    </StyledDivWrapper>
    <ContactForm volumes={[volumes.no1, volumes.no2, volumes.no3]} {...props} />
  </Layout>
)

export default IndexPage
